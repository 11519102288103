/**
 * BE STUDIO
 * BLIND TIGER
 * Web Application
 *
 * Jewellery Single Product Page Logic
 *
 * @author E. Lew & B. Mockute
 * @copyright (C)2022, BE Studio
 * @see www.its-be-studio.com
 * @version 1.x
 */
function getJewelleryProduct(id) {
  function getAttributes(attributes) {
    const output = {
      materials: null,
      circLength: null,
      beadSize: null
    };
    for(let attribute of attributes) {
      switch(attribute.name) {
        case "Materials":
          output.materials = sanitize(attribute.value);
          break;
        case "Circumferential Length":
          output.circLength = sanitize(attribute.value);
          break;
        case "Bead Size":
          output.beadSize = sanitize(attribute.value);
          break;
        default:
          break;
      }
    }

    return output;
  }

  function replaceZeroes(text) {
    return text.replace(/0/g, "O");
  }

  setTimeout(() => {
    const code = id;
    fetch('https://app.ecwid.com/api/v3/80677026/products/' + code + "?token=public_Amf6mCv4hZQC4Zjdc7yaXCqS55ZbyGD4")
    .then((response) => response.json())
    .then((data) => {
      setTimeout(() => {
        const title = document.querySelector(".product-title");
        const price = document.querySelector(".product-price");
        const desc = document.querySelector(".product-description");
        const materials = document.querySelector(".product-materials");
        const circLength = document.querySelector(".product-circ-length");
        const beadSize = document.querySelector(".product-bead-size");
        const attributes = getAttributes(data.attributes);

        title.innerHTML = sanitize(data.name + ":<br />" + data["subtitleTranslated"]["en"]);
        price.innerHTML = replaceZeroes(sanitize("$ " + data["price"]));
        if(desc) {
          desc.innerHTML = sanitize(data.description);
        }
        materials.innerHTML = attributes.materials;
        circLength.innerHTML = replaceZeroes(attributes.circLength);
        beadSize.innerHTML = replaceZeroes(attributes.beadSize);
      }, 2000);


    })
    .catch((error) => console.log(error));
  }, 1000);
}
